import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import office from "../assets/img/ank.jpg"
import office1 from "../assets/img/ist.jpg"
import peopleIcon from "../assets/img/people-icon.png"
import mailIcon from "../assets/img/mail-icon.png"
import callIcon from "../assets/img/call-icon.png"
import insta1 from "../assets/img/flatart-insta-ss1.png"
import insta2 from "../assets/img/flatart-insta-ss2.png"

class Contact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: "",
    }
  }
  handleSubmit(e) {
    e.preventDefault()
    const response = fetch(
      "https://forms.flatart.com.tr/index.php?r=app%2Ff&id=8",
      {
        method: "POST",
        body: JSON.stringify({
          text_457735: document.querySelector("#ad").value,
          text_967447: document.querySelector("#eposta").value,
          text_314200: document.querySelector("#sirket").value,
          text_901991: document.querySelector("#mesaj").value,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
    response.then(res => {
      if (res.status === 200) {
        document.querySelector(".status").innerHTML =
          "Mesajınız başarıyla gönderilmiştir."
        document.querySelector("#ad").value = ""
        document.querySelector("#eposta").value = ""
        document.querySelector("#sirket").value = ""
        document.querySelector("#mesaj").value = ""
      } else {
        document.querySelector(".status").innerHTML = "Bir hata oluştu."
      }
    })
  }
  render() {
    return (
      <Layout>
        <SEO
          title="Contact"
          description="You can contact us by visiting our offices in Ankara and Istanbul or filling out the contact form on our website."
        />
        <div className="contact container">
          <h3>CONTACT US</h3>
          <h1 id="contactH1">We are at the connection<br/> point of the Asian and <br/>European continents.</h1>
          <p>We welcome you to our R&D office in Ankara and our head office in Istanbul.</p>
                    
          <div className="offices">
            <div className="offices-city col-md-6">
              <img alt="alt text" src={office}></img>
              <h2>Ankara</h2>
              <p>Ümit Mah. Bina No:2 Nokta Kule<br />
              5. Kat Daire No:6 Çankaya/ANKARA</p>
              <a target="_blank" rel="noopener noreferrer"
                href="https://www.google.com/maps/dir/39.9048704,32.6926336/flatart/@39.9029452,32.6905496,17z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14d3470555555555:0x573dd00dc266ec0f!2m2!1d32.6929533!2d39.900412"
                className="content-link" > Get an address description for our Ankara office.</a>
            </div>
            <div className="offices-city col-md-6">
              <img alt="alt text" src={office1}></img>
              <h2>Istanbul</h2>
              <p>
                Dikilitaş Mah. Yenigelin Sok.<br /> No:6/15 Beşiktaş/İSTANBUL
              </p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/Dikilita%C5%9F,+Yenigelin+Sk+No:6,+34349+Be%C5%9Fikta%C5%9F%2F%C4%B0stanbul/data=!4m2!3m1!1s0x14cab7ac61a593c7:0xdf5d5dc3a07ab31f?sa=X&ved=2ahUKEwiIq5el47nmAhUktHEKHaZBDoMQ8gEwAHoECAsQAQ"
                className="content-link"
              >
                Get an address description for our Istanbul office.
              </a>
            </div>
          </div>
        </div>
        <div className="contact-row container">
          <div className="col-md-4">
            <img alt="alt text" src={peopleIcon}></img>
            <p>Find out what we can do for your collaborations and project.</p>
            <a className="content-link" href="mailto:partner@flatart.agency">partners@flatart.com.tr</a>
          </div>
          <div className="col-md-4">
            <img alt="alt text" src={mailIcon}></img>
            <p>Send us an email to meet, and we will reply in one day.</p>
            <a className="content-link" href="mailto:hi@flatart.agency">hi@flatart.com.tr</a>
          </div>
          <div className="col-md-4">
            <img alt="alt text" src={callIcon}></img>
            <p>For urgent matters,<br/> give us a call!<br/> (Available on Whatsapp)</p>
            <a href="tel:+908509880200" className="content-link">+90 533 577 72 39</a>
          </div>
        </div>

                <div className="contact-end container">
                    <div className="col-md-6">
                        <h2>The easiest way <br/> to communicate.</h2>
                        <p>We return within 1 business day.</p>
                        <form className="contact-end__form" onSubmit={this.handleSubmit}>
                            <input type="text" name="text_457735" id="ad" placeholder="Enter your name*" />
                            <input type="email" name="text_967447" id="eposta" placeholder="Enter your email*" />
                            <input type="text" name="text_314200" id="sirket" placeholder="Enter your company" />
                            <textarea id="mesaj" name="text_901991" placeholder="Enter your message"></textarea>
                            <button className="red-button">SEND</button>
                            <div className="status"></div>
                        </form>
                    </div>
                    <div className="col-md-6">
                        <div className="follow">
                            <h2>Contact us about  <br/>our services and your questions!</h2>
                        </div>
                        <div className="row">
            
            <div className="col">
               <a href="/brands/" target="_blank" ><img alt="flatart-basari-hikayeleri"  src={insta1} /></a>
            </div>
            <div className="col">
               <a href="mailto:hello@theflatart.com" target="_blank" ><img alt="flatart-hikayeler"  src={insta2} /></a>
            </div>

           </div>
                    </div>
                </div>
      </Layout>
    )
  }
}

export default Contact
